export const ANALYTIC_EVENT = {
  CHAT_LINK_CLICKED: 'chat_link_clicked',
  USER_SETTINGS_UPDATED: 'user_settings_updated',
  USER_SETTINGS_OPENED: 'user_settings_opened',
  QUEST_STARTED_CLICKED: 'quest_started_clicked',
  QUEST_REPLAY_CLICKED: 'quest_replay_clicked',
  QUEST_RESUMED_CLICKED: 'quest_resumed_clicked',
  SWITCH_QUEST_CLICKED: 'switch_quest_clicked',
  START_JOURNEY_CLICKED: 'start_journey_clicked',
  START_JOURNEY_APPROVED: 'start_journey_approved',
  CATALOG_QUEST_CARD_CLICKED: 'catalog_quest_card_clicked',
  CATALOG_JOURNEY_CARD_CLICKED: 'catalog_journey_card_clicked',
  CATALOG_QUEST_USER_IMAGE_CLICKED: 'catalog_quest_user_image_clicked',
  CATALOG_FILTER_SELECTED: 'catalog_filter_selected',
  SNACK_BUTTON_CLICKED: 'snack_button_clicked',
  USER_LOGGED_OUT: 'user_logged_out',
  FEED_SCROLL_BTN_CLICKED: 'feed_scroll_btn_clicked',
  FEED_FINISHED_QUEST_CLICKED: 'feed_finished_quest_clicked',
  FRIEND_INVITED: 'friend_invited',
  GOOGLE_CONTACTS_INVITE_CLICKED: 'google_contacts_invite_clicked',
  GOOGLE_CONTACTS_SKIP_CLICKED: 'google_contacts_skip_clicked',
  REFERRAL_COPY_LINK_CLICKED: 'referral_copy_link_clicked',
  SWITCH_QUEST_APPROVED: 'switch_quest_approved',
  SWITCH_FRAMEWORK_APPROVED: 'switch_framework_approved',
  REPLAY_QUEST_APPROVED: 'replay_quest_approved',
  NOTIFICATION_CTA: 'notification_cta',
  WILCOINS_ICON_HOVERED: 'wilcoins_icon_hovered',
  SKILLS_PANEL_TOGGLED: 'skills_panel_toggled',
  NAVIGATION_CLICKED: 'navigation_clicked',
  BURGER_MENU_ITEM_CLICKED: 'burger_menu_item_clicked',
  FEATURE_FLAG_TREATMENT: 'feature_flag_treatment',
  EDITOR_UPLOAD_NEW_QUEST_CLICKED: 'editor_upload_new_quest_clicked',
  EDITOR_UPLOAD_VERSION_CLICKED: 'editor_upload_version_clicked',
  EDITOR_TEST_QUEST_CLICKED: 'editor_test_quest_clicked',
  EDITOR_SUBMIT_QUEST_CLICKED: 'editor_submit_quest_clicked',
  EDITOR_REMOVE_QUEST_FROM_REVIEW_CLICKED:
    'editor_remove_quest_from_review_clicked',
  EDITOR_PUBLISH_QUEST_CLICKED: 'editor_publish_quest_clicked',
  EDITOR_DELETE_QUEST_CLICKED: 'editor_delete_quest_clicked',
  SNACK_ASK_FOR_HELP: 'snack_ask_for_help',
  SNACK_ASK_FOR_SOLUTION: 'snack_ask_for_solution',
  SNACK_CONTACT_SUPPORT: 'snack_contact_support',
  SNACK_BACK_TO_WILCO: 'snack_back_to_wilco',
  SNACK_GUEST_SIGNUP: 'snack_guest_signup',
  CHAT_INPUT_CLICKED: 'chat_input_clicked',
  SHARE_TO_SOCIAL_CLICKED: 'share_to_social_clicked',
  SHARE_PORTFOLIO_TO_SOCIAL_CLICKED: 'share_portfolio_to_social_clicked',
  PORTFOLIO_EDIT_BUTTON_CLICKED: 'portfolio_edit_button_clicked',
  PORTFOLIO_VIEW_MORE_CLICKED: 'portfolio_view_more_clicked',
  PORTFOLIO_VIEW_LESS_CLICKED: 'portfolio_view_less_clicked',
  CHAT_OPENED: 'chat_opened',
  START_QUEST_FROM_SNACK: 'start_quest_from_snack',
  CERTIFICATE_LINK_CLICKED: 'certificate_link_clicked',
  DIRECT_QUEST_SIGNUP_CLICKED: 'direct_quest_signup_clicked',
  DIRECT_QUEST_START_CLICKED: 'direct_quest_start_clicked',
  CERTIFICATE_ADD_TO_LINKEDIN_CLICKED: 'certificate_add_to_linkedin_clicked',
};
