import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import {
  APP_ACTIONS,
  AppContext,
  USER_AUTH_LIFECYCLE_ACTIONS,
  QUESTS_ACTIONS,
} from './appContext';
import { FETCH_ACTIONS } from './Fetcher';
import {
  QUEST_QUERIES_KEYS,
  useCatalogQuests,
} from './wilco-engine/queries/quest';

const DataLoader = () => {
  const {
    state: {
      fetch: { [FETCH_ACTIONS.FETCH_USER_INFORMATION]: updatedUser },
      user: currentUser,
    },
    dispatch,
  } = useContext(AppContext);
  const fetchedCatalogQuests = useCatalogQuests();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentUser?.id) {
      dispatch({
        type: APP_ACTIONS.FETCH,
        payload: { action: FETCH_ACTIONS.FETCH_USER_INFORMATION },
      });
      queryClient.refetchQueries(QUEST_QUERIES_KEYS.CATALOG_QUESTS);
    }
  }, [dispatch, currentUser?.id, queryClient]);

  useEffect(() => {
    const data = updatedUser?.data;
    if (data) {
      dispatch({
        type: USER_AUTH_LIFECYCLE_ACTIONS.UPDATE_USER,
        payload: updatedUser.data,
      });
    }
  }, [dispatch, updatedUser]);

  useEffect(() => {
    if (fetchedCatalogQuests?.data) {
      dispatch({
        type: QUESTS_ACTIONS.SET_CATALOG_QUESTS,
        payload: fetchedCatalogQuests.data,
      });
    }
  }, [dispatch, fetchedCatalogQuests?.data]);

  return null;
};

export default DataLoader;
